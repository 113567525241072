import Register from "@/js/utils/Register";

export default class GoogleRecaptcha {

	constructor (menu) {
		const _this = this;
		document.querySelectorAll('.g-recaptcha').forEach((el) => {
			if (el.children.length) {
				return ;
			}
			grecaptcha.render(el, {}, true);
		});
	}

	static init ($container) {
		if ($container.find('.g-recaptcha').length > 0) {
			new GoogleRecaptcha($container);
		}
	}

}

(new Register()).registerCallback(GoogleRecaptcha.init, 'GoogleRecaptcha.init');