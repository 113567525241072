import Register from "@/js/utils/Register";

export default class Tabs {

	tabs = [];

	tabContainer = null;

	constructor (el) {
		const _this = this;
		this.tabContainer = el;

		el.querySelectorAll('[data-tab-link]').forEach((el, i) => {
			if (i == 0) {
				el.parentElement.classList.add('active');
			}
			let href = el.getAttribute('href').substring(1);
			_this.tabs.push(href);
			el.addEventListener('click', (e) => {
				e.preventDefault();
				_this.openTab(href)
			});
		});

		this.tabs.forEach((item, i) => {
			if (i !== 0) {
				_this.tabContainer.querySelector('#' + item).style.display = 'none';
			}
		});

	}

	openTab(href) {
		this.tabContainer.querySelectorAll('[data-tab-link]').forEach((el, i) => {
			if (href !== el.getAttribute('href').substring(1)) {
				el.parentElement.classList.remove('active');
			} else {
				el.parentElement.classList.add('active');
			}
		});

		this.tabs.forEach((item, i) => {
			this.tabContainer.querySelector('#' + item).style.display = 'none';
		});

		this.tabContainer.querySelector('#' + href).style.display = 'block';
	}

	static init ($container) {
		$container.find('[data-tabs]').each(function(i, el) {
			new Tabs(el);
		});
	}

}

(new Register()).registerCallback(Tabs.init, 'Tabs.init');