export default class MainMenu {

	constructor (menu) {
		const _this = this;

		[...menu.querySelectorAll('.dropdown-toggle')].map(dropdownToggleEl => {
			let dropdown = new bootstrap.Dropdown(dropdownToggleEl.parentElement);
			let dropdownStatus = false;

			dropdownToggleEl.parentElement.addEventListener('hidden.bs.dropdown', () => dropdownStatus = false);
			dropdownToggleEl.parentElement.addEventListener('shown.bs.dropdown', () => dropdownStatus = true);

			dropdownToggleEl.addEventListener('click', (e) => {
				e.preventDefault();
				dropdown.toggle();
			});

			if (window.outerWidth >= 768) {
				let hover = false;
				dropdownToggleEl.parentElement.parentElement.addEventListener('mouseenter', () => {
					if (dropdownStatus === false) {
						dropdown.show();
					}
				});
				dropdownToggleEl.parentElement.parentElement.addEventListener('mouseleave', () => {
					if (dropdownStatus === true) {
						dropdown.hide();
					}
				});
			}
		});
	}

	static init ($container) {
		const $menu = $container.find('#navbarResponsive');
		if ($menu.length > 0) {
			new MainMenu($menu[0]);
		}
	}

}