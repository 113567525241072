// UI for front
// console.log('Front');

// Required dependencies
import 'jquery';
global.$ = global.jQuery = jQuery;

// Assets
import '../../../theme/front/style.scss';

import '../../components/naja';
import '../../components/netteForms';
import onDomReady from '../../utils/onDomReady';
import '../../components/naja-errorHandler';
import '../../components/fid-remover';
import {SpinnerExtension} from '../../components/naja-spinner';
import {ModalExtension} from '../../components/naja-modal';
import '../../components/bootstrap';
import initBootstrapToasts from '../../components/bootstrap-toast';
import blueimp from 'blueimp-gallery';
import 'blueimp-gallery/js/blueimp-gallery-indicator';
import 'owl.carousel';
import MainMenu from "@/js/components/MainMenu";
import GoogleRecaptcha from "@/js/components/GoogleRecaptcha";
import Tabs from "@/js/components/Tabs";
import '../../utils/utils';

import '../../components/Spinbox';

import Register from '../../utils/Register';

naja.registerExtension(new SpinnerExtension('body'));
naja.registerExtension(new ModalExtension('front'));
naja.formsHandler.netteForms = Nette;

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    (new Register()).registerNewContent($(event.detail.snippet));
	if (event.detail.snippet.id === 'snippet--flashes') {
		initBootstrapToasts();
	}
});

onDomReady(() => {	
	
	/*
	NAJA
	history: false,
    forceRedirect: true,
	*/

	naja.initialize();
	initBootstrapToasts();

	document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
	
    $('body').on('click', '.bi-gallery a:hrefImage', function(event) {	
        event = event || window.event;
        var target = event.target || event.srcElement, link = target.src ? target.parentNode : target, options = {index: link, event: event};
        var links = $(this).closest('.bi-gallery').find('a:hrefImage');
        blueimp(links, options);
    });
    
	$('body').on('click', '.bi-p-gallery a[data-bi]:hrefImage', function(event) {
        event = event || window.event;
        var target = event.target || event.srcElement, link = target.src ? target.parentNode : target, options = {index: link, event: event};
        var links = $('.bi-p-gallery a[data-bi]');
        blueimp(links, options);
    });

	$('.owl-carousel').owlCarousel({
		items:1,
		loop:true,
		autoplayHoverPause: false,
		autoplay: true,
		autoplayTimeout: 6000,
		smartSpeed: 1000,
	});

 	MainMenu.init($('body'));

    (new Register()).registerNewContent($('body'));
    	
});

// Webpack HMR
if (module.hot) {
	module.hot.accept();
}